import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наши услуги | Наши морские предложения в Victoria Sea Club
			</title>
			<meta name={"description"} content={"Отплытие на элегантном судне - откройте для себя наши уникальные морские услуги"} />
			<meta property={"og:title"} content={"Наши услуги | Наши морские предложения в Victoria Sea Club"} />
			<meta property={"og:description"} content={"Отплытие на элегантном судне - откройте для себя наши уникальные морские услуги"} />
			<meta property={"og:image"} content={"https://voxeton.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://voxeton.com/img/images.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://voxeton.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://voxeton.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://voxeton.com/img/images.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://voxeton.com/img/images.png"} />
			<meta name={"msapplication-TileImage"} content={"https://voxeton.com/img/images.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://voxeton.com/img/4.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
					Обзор услуг
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
					В компании Victoria Sea Club мы гордимся тем, что предлагаем флот судов и услуги, разработанные для обеспечения беспроблемного морского путешествия. Ищите ли вы уединения или празднуете с друзьями - наши яхты станут вашим пристанищем на волнах.

				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-8">
			<Text margin="0px 0px 35px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Категории услуг
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Круизные яхты для одиночек и дуэтов
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Идеально подходящие для уединенного отдыха или романтических приключений, эти яхты создают идеальные условия для создания заветных воспоминаний.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Групповые и семейные путешествия
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Просторные и вместительные, наши большие суда гарантируют, что все находящиеся на борту смогут вместе получить удовольствие от морских путешествий.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Однодневные экскурсии
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Насладитесь солнцем и морским бризом во время однодневной экскурсии, предназначенной для отдыха или приключений, по вашему желанию.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Выходные
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Вырвитесь из обыденности, проведя выходные на волнах со всеми удобствами, чтобы стать домом вдали от дома.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Комфорт на борту:
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Наши яхты оснащены всем необходимым для отдыха и релаксации, что гарантирует приятные ощущения во время плавания по воде.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Экологически чистая практика
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Мы заботимся о защите морской среды, предлагая экологические удобства, которые обеспечивают устойчивое плавание.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});